* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: inherit;
  list-style: none;
}
*::-webkit-scrollbar,
*::-webkit-scrollbar,
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: transparent;
}
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 10px;
}
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
  border: transparent 3px solid;
  background: transparent;
}
a {
  text-decoration: none;
  color: inherit;
}
a:visited {
  color: inherit;
}
button {
  border: none;
}
input::placeholder {
  font-size: 0.8em;
  color: #aaa;
}
input {
  border: none;
  text-indent: 10px;
}
input:focus {
  outline: none;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
textarea {
  resize: none;
}

.no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pointer {
  cursor: pointer;
}
